import {
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { useMutation, useQuery } from 'react-query';
import { getUser, updateCurrentUser } from 'api/auth';
import { createAddress } from 'api/Dashboard/addresses';
import { setLocalStorage } from 'utils/localStorage';
import PersonalInfoForm from 'components/Dashboard/Profile/PersonalInfoForm';
import LocationDetailsForm from 'components/Dashboard/LocationDetailsForm';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const { data: userData = {}, isLoading: isUserDataLoading } = useQuery(
    'user',
    getUser,
    {
      refetchOnMount: true,
      cacheTime: 100,
    }
  );

  const personalInfoDefaultValues = {
    avatar: [{ base64: userData?.avatar }],
    firstname: userData.name?.split(' ')[0],
    lastname: userData.name?.split(' ')[1],
    email: userData.email,
    phone: userData.phone,
  };

  const handleUpdateSuccess = () => {
    toast({
      title: t('profileUpdatedSuccessfully'),
      status: 'success',
      duration: 2000,
      isClosable: true,
    });

    navigate('/');
  };

  const {
    mutate: updateUserInfo,
    isLoading: isUpdateUserInfo,
    error: updateUserInfoError,
  } = useMutation(updateCurrentUser, {
    onSuccess: (data) => {
      setLocalStorage('user', data.data);
      handleUpdateSuccess();
    },
  });

  const { mutate: createAddressMutation, isLoading: isCreateAddressLoading } =
    useMutation(createAddress, {
      onSuccess: () => {
        handleUpdateSuccess();
      },
    });

  const handleAddressSubmit = (data) => {
    const payload = {
      address: data.address,
      pocs_attributes: [
        {
          name: userData.name,
          phone_number: userData.phone,
          main: true,
        },
      ],
    };

    createAddressMutation(payload);
  };

  const addressDefaultValue = userData.addresses;

  const handleUserInfoSubmit = (data) => {
    const newAvatar = data.avatar[0];

    const avatarPayload =
      newAvatar.base64 === userData?.avatar
        ? undefined
        : { ...newAvatar, base64: newAvatar.base64?.split(',')[1] };

    const user = {
      ...data,
      avatar: avatarPayload,
    };

    updateUserInfo(user);
  };

  const handleDeleteAvatar = () => {
    const user = {
      avatar: {
        _destroy: '1',
      },
    };

    updateUserInfo(user);
  };

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={6} paddingTop={2}>
      <GridItem colSpan={{ base: 6, md: 4 }}>
        <Card size="lg">
          <CardHeader>
            <SectionTitle title={t('personalInformation')} />
          </CardHeader>
          <CardBody>
            {isUserDataLoading ? (
              <Spinner color="primary.500" />
            ) : (
              <PersonalInfoForm
                onSubmit={handleUserInfoSubmit}
                defaultValues={personalInfoDefaultValues}
                isLoading={isUpdateUserInfo}
                onDelete={handleDeleteAvatar}
                backendErrors={updateUserInfoError?.response.data.errors}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem colSpan={{ base: 6, md: 2 }}>
        <Card borderRadius="xl" size="lg">
          <CardHeader>
            <SectionTitle title={t('locationDetails')} />
          </CardHeader>
          <CardBody>
            <LocationDetailsForm
              onSubmit={handleAddressSubmit}
              defaultValues={{ address: addressDefaultValue ?? {} }}
              isUpdate={!!addressDefaultValue}
              isLoading={isCreateAddressLoading}
            />
          </CardBody>
        </Card>
      </GridItem>
    </Grid>
  );
};

export default Profile;
