import {
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Grid,
  GridItem,
  Button,
  useDisclosure,
  Stack,
  Box,
  Show,
  Flex,
  Hide,
  Text,
  useMediaQuery,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import Select from 'components/Dashboard/Select';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import DatePicker from 'components/shared/Inputs/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { flattenErrorObject } from 'utils/formError';
import { createAddress, getAddresses } from 'api/Dashboard/addresses';
import WithAddNewButton from 'components/Dashboard/WithAddNewButton';
import CreateButtonsContainer from 'components/Dashboard/CreateButtonsContainer';
import AddressFormDrawer from 'components/Dashboard/Orders/AddressFormDrawer';
import { getLocalStorage } from 'utils/localStorage';
import { createTransportationOrder } from 'api/Dashboard/transportation';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import BackToListButton from 'components/Dashboard/BackToListButton';
import MapWithDirections from 'components/shared/MapWithDirections';
import Input from 'components/shared/Inputs/Input';
import AsyncSelect from 'components/Dashboard/AsyncSelect';
import { useState } from 'react';
import {
  packagingEnum,
  truckSizeEnum,
  truckTypeEnum,
} from 'constants/dashboard';
import { adjustToUTC } from 'utils/date';
import TablePage from 'components/Dashboard/TablePage';
import SKUsTableForm from 'components/Dashboard/Orders/SKUsTableForm';
import { createColumnHelper } from '@tanstack/react-table';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import SKUFormDrawer from 'components/Dashboard/SKUs/SKUFormDrawer';
import { createSKU } from 'api/Dashboard/skus';
import styled from '@emotion/styled';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import i18n from 'utils/i18n';

const StyledEditIcon = styled(EditIcon)`
  max-width: 20px;
  max-height: 20px;
  path {
    stroke: #fff;
  }

  path:last-of-type {
    stroke: #fff;
  }
`;

const convertTimeTo12Hr = (hour) => {
  const formattedHour = hour % 12 || 12;
  const meridiem = hour < 12 ? 'AM' : 'PM';

  return `${formattedHour.toString().padStart(2, '0')}:00 ${i18n.t(meridiem)}`;
};

export const generateTimeSlots = (startTime, endTime, interval) => {
  const timeSlots = [];
  let currentHour = startTime;

  while (currentHour <= endTime) {
    const start = convertTimeTo12Hr(currentHour);
    const end = convertTimeTo12Hr(currentHour + interval);
    const timeSlot = `${start} - ${end}`;
    timeSlots.push(timeSlot);

    currentHour += interval;
  }

  return timeSlots;
};

const pickupTimeSlots = generateTimeSlots(7, 21, 2);

const getAddressById = (id, data) => data?.find((address) => address.id === id);

const addressToLocation = (address) => {
  if (!address) {
    return address;
  }

  return {
    lat: address.latitude,
    lng: address.longitude,
  };
};

const getPackagingLabel = (value) =>
  Object.keys(packagingEnum).find((key) => packagingEnum[key] === value);

const columnHelper = createColumnHelper();

export const TransportationPackingList = ({
  skus,
  onSKUSubmit,
  onSKUChange,
  onSKUDelete,
}) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [editSKUId, setEditSKUId] = useState();

  const queryClient = useQueryClient();

  const {
    data: createSKUData,
    mutate: createSKUMutation,
    isLoading: isCreateSKULoading,
    error: createSKUErrors,
  } = useMutation(createSKU, {
    onSuccess: async () => {
      await queryClient.refetchQueries('skus');
      onClose();
    },
  });

  const columns = [
    columnHelper.accessor('name', {
      cell: ({ getValue }) => <Text fontWeight={600}>{getValue()}</Text>,
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('system_sku', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('packaging.label', {
      cell: ({ getValue, row }) => {
        const id = row.original.id;

        if (id === editSKUId) {
          const value = getValue();

          const defaultValue = {
            label: t(value),
            value: packagingEnum[value],
          };

          return (
            <Select
              placeholder={t('packagingType')}
              options={packagingOptions}
              onChange={({ value }) => {
                handleSkuChange(id, {
                  key: 'packaging',
                  value,
                });
              }}
              defaultValue={defaultValue}
            />
          );
        }
        return (
          <Text fontWeight={500} textTransform="capitalize">
            {t(getValue())}
          </Text>
        );
      },
      header: t('selectedPackaging'),
      enableSorting: false,
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue, row }) => {
        const id = row.original.id;

        if (id === editSKUId) {
          const defaultValue = getValue();

          return (
            <DebouncedNumberInput
              placeholder="quantity"
              name="quantity"
              defaultValue={defaultValue}
              min={1}
              onChange={(value) => {
                handleSkuChange(id, {
                  key: 'quantity',
                  value,
                });
              }}
            />
          );
        }
        return <Text fontWeight={500}>{getValue()}</Text>;
      },
      header: t('selectedQuantity'),
      enableSorting: false,
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        const id = row.original.id;

        const handleDelete = () => {
          onSKUDelete(row.original.id);
        };

        const handleEdit = () => {
          setEditSKUId((prevValue) => (prevValue === id ? undefined : id));
        };

        if (isMobile) {
          return (
            <Menu>
              <MenuButton as={IconButton} icon={<DotsVerticalIcon />} />
              <MenuList>
                <MenuItem textTransform="capitalize" onClick={handleEdit}>
                  {t('edit')}
                </MenuItem>
                <MenuItem
                  textTransform="capitalize"
                  color="red.500"
                  onClick={handleDelete}
                >
                  {t('remove')}
                </MenuItem>
              </MenuList>
            </Menu>
          );
        }

        return (
          <Flex gap={3} justifyContent="center">
            <Button
              variant="outline"
              color="gray.600"
              textTransform="capitalize"
              fontWeight={500}
              onClick={handleEdit}
            >
              {t('edit')}
            </Button>
            <Button
              variant="outline"
              color="red.600"
              textTransform="capitalize"
              fontWeight={500}
              onClick={handleDelete}
            >
              {t('remove')}
            </Button>
          </Flex>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
      enableSorting: false,
    }),
  ];

  const handleSkuChange = (id, { key, value }) => {
    onSKUChange(id, { key, value });
  };

  const packagingOptions = Object.keys(packagingEnum).map((key) => ({
    label: t(key),
    value: packagingEnum[key],
  }));

  const handleSkuSubmit = (sku, onSuccessCallback) => {
    const selectedSKUData = queryClient
      .getQueriesData(['skus'])[0]?.[1]
      ?.data.find((skuData) => skuData.id === sku.sku_id);

    const packagingLabel = getPackagingLabel(sku.packaging);

    const selectedSKU = {
      ...selectedSKUData,
      quantity: sku.quantity,
      packaging: {
        value: sku.packaging,
        label: packagingLabel,
      },
    };

    onSKUSubmit(selectedSKU);
    onSuccessCallback();
  };

  return (
    <TablePage
      data={skus}
      columns={columns}
      title={<SectionTitle title={t('packingList')} hideDivider />}
      hidePagination
      hideSearch
      enableSorting={false}
      tableAction={
        <>
          <Button
            leftIcon={<StyledEditIcon />}
            colorScheme="primary"
            textTransform="capitalize"
            size="lg"
            onClick={onOpen}
          >
            {t('skusPages.createNewSKU')}
          </Button>

          <SKUFormDrawer
            isOpen={isOpen}
            onClose={onClose}
            backendErrors={flattenErrorObject(
              createSKUErrors?.response.data.errors
            )}
            isLoading={isCreateSKULoading}
            onSubmit={(payload) => createSKUMutation(payload)}
          />
        </>
      }
      footer={
        <Box pb={6} mt={4}>
          {!isCreateSKULoading && (
            <SKUsTableForm
              defaultValue={{
                sku_id: createSKUData?.data.id,
              }}
              onSubmit={handleSkuSubmit}
              hideExpiry
            />
          )}
        </Box>
      }
    />
  );
};

const TransportationCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pageTitle } = useOutletContext();
  const toast = useToast();

  const queryClient = useQueryClient();

  const user = getLocalStorage('user') ?? {};

  const [addressForCreate, setAddressForCreate] = useState();

  const [skus, setSKUs] = useState([]);

  const {
    isOpen: isAddressOpen,
    onOpen: onAddressOpen,
    onClose: onAddressClose,
  } = useDisclosure();

  const handleAddressClose = () => {
    setAddressForCreate('');
    onAddressClose();
  };

  const {
    mutate: createAddressMutation,
    isLoading: isCreateAddressLoading,
    error: createAddressError,
  } = useMutation(createAddress, {
    onSuccess: async (response) => {
      await queryClient.refetchQueries('addresses');
      setValue(addressForCreate, response.data.data.id);
      handleAddressClose();
    },
  });

  const {
    handleSubmit,
    formState,
    control,
    register,
    setValue,
    watch,
    getValues,
    trigger,
  } = useForm();

  const watchScheduleOn = watch('date_of_trip');
  const watchOrigin = watch('origin_id');
  const watchDestination = watch('destination_id');

  const addressesData = queryClient.getQueriesData(['addresses'])[0]?.[1]?.data;

  const selectedOrigin = addressToLocation(
    getAddressById(watchOrigin, addressesData)
  );

  const selectedDestination = addressToLocation(
    getAddressById(watchDestination, addressesData)
  );

  const {
    mutate: createTransportationOrderMutation,
    isLoading: isCreateTransportationOrderLoading,
    error,
  } = useMutation((payload) =>
    createTransportationOrder(user.organization.id, payload)
  );

  const errors = {
    ...formState.errors,
    ...flattenErrorObject(error?.response?.data.errors),
  };

  const onSubmit = (data, isDraft) => {
    if (skus.length === 0) {
      toast({
        title: t('skuError'),
        status: 'error',
        duration: 5000,
        isClosable: false,
      });
      return;
    }

    const payload = {
      ...data,
      date_of_trip: adjustToUTC(data.date_of_trip),
      origin_id: '' + data.origin_id,
      destination_id: '' + data.destination_id,
      transportation_order_attributes: {
        ...data.transportation_order_attributes,
        num_of_labors: +data.transportation_order_attributes.num_of_labors,
        truck_requests_attributes: [
          {
            ...data.transportation_order_attributes.truck_requests_attributes,
            number_of_trucks:
              +data.transportation_order_attributes.truck_requests_attributes
                .number_of_trucks,
          },
        ],
      },
      ab_trip_skus_attributes: skus.map((sku) => ({
        sku_id: '' + sku.id,
        packaging: sku.packaging.value,
        quantity: +sku.quantity,
      })),
    };

    createTransportationOrderMutation(payload, {
      onSuccess: (data) => {
        if (isDraft) {
          navigate('/transportations');
          return;
        }

        navigate(`/transportations/${data.id}/summary`);
      },
    });
  };

  const truckTypeOptions = Object.keys(truckTypeEnum).map((key) => ({
    label: t(key),
    value: truckTypeEnum[key],
  }));

  const truckSizeOptions = Object.keys(truckSizeEnum).map((key) => ({
    label: t(key),
    value: truckSizeEnum[key],
  }));

  const pickupTimeOptions = pickupTimeSlots.map((slot) => ({
    label: slot,
    value: slot,
  }));

  return (
    <Stack spacing={6} mb={{ base: 16, md: 0 }}>
      <Box>
        <Show below="md">
          <Box mb={2} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} wrap />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton
              title={t('transportationsList')}
              url="/transportations"
            />
          </Hide>
        </Flex>
      </Box>

      <form onSubmit={handleSubmit((data) => onSubmit(data))} noValidate>
        <Grid templateColumns="repeat(7, 1fr)" gap={6}>
          <GridItem colSpan={{ base: 7, md: 5 }}>
            <Card size="lg">
              <CardHeader>
                <SectionTitle title={t('transportationTripDetails')} />
              </CardHeader>
              <CardBody>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <WithAddNewButton
                    onClick={() => {
                      setAddressForCreate('origin_id');
                      onAddressOpen();
                    }}
                  >
                    <Controller
                      control={control}
                      name="origin_id"
                      rules={{ required: 'thisFieldIsRequired' }}
                      render={({ field: { onChange, value } }) => (
                        <AsyncSelect
                          performSearchRequest={getAddresses}
                          entityKey="addresses"
                          label={t('origin')}
                          onChange={(option) => onChange(option?.value)}
                          value={value}
                          error={errors?.origin_id}
                          size="lg"
                          placeholder={t('pleaseSelectTheOrigin')}
                          required
                        />
                      )}
                    />
                  </WithAddNewButton>
                  <WithAddNewButton
                    onClick={() => {
                      setAddressForCreate('destination_id');
                      onAddressOpen();
                    }}
                  >
                    <Controller
                      control={control}
                      name="destination_id"
                      rules={{ required: 'thisFieldIsRequired' }}
                      render={({ field: { onChange, value } }) => (
                        <AsyncSelect
                          performSearchRequest={getAddresses}
                          entityKey="addresses"
                          label={t('destination')}
                          onChange={(option) => onChange(option?.value)}
                          value={value}
                          error={errors?.destination_id}
                          size="lg"
                          placeholder={t('pleaseSelectTheDestination')}
                          required
                        />
                      )}
                    />
                  </WithAddNewButton>

                  <Controller
                    control={control}
                    name="transportation_order_attributes.truck_requests_attributes.truck_type"
                    rules={{ required: 'thisFieldIsRequired' }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label={t('truckType')}
                        placeholder={t('pleaseSelectTheTruckType')}
                        options={truckTypeOptions}
                        onChange={({ value }) => onChange(value)}
                        error={
                          errors?.transportation_order_attributes
                            ?.truck_requests_attributes?.truck_type
                        }
                        size="lg"
                        required
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="transportation_order_attributes.truck_requests_attributes.truck_size"
                    rules={{ required: 'thisFieldIsRequired' }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label={t('truckSize')}
                        placeholder={t('pleaseSelectTheTruckSize')}
                        options={truckSizeOptions}
                        onChange={({ value }) => onChange(value)}
                        error={
                          errors?.transportation_order_attributes
                            ?.truck_requests_attributes?.truck_size
                        }
                        size="lg"
                        required
                      />
                    )}
                  />

                  <DatePicker
                    name="date_of_trip"
                    label={t('scheduledOn')}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    required="thisFieldIsRequired"
                    minDate={new Date()}
                    formValue={watchScheduleOn}
                    control={control}
                    size="lg"
                  />

                  <Controller
                    control={control}
                    name="time_of_pickup"
                    rules={{ required: 'thisFieldIsRequired' }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label={t('pickupTime')}
                        placeholder={t('timeOfPickup')}
                        options={pickupTimeOptions}
                        onChange={({ value }) => onChange(value)}
                        error={errors?.time_of_pickup}
                        size="lg"
                        required
                      />
                    )}
                  />

                  <Input
                    name="transportation_order_attributes.truck_requests_attributes.number_of_trucks"
                    label={t('numberOfTrucks')}
                    register={register}
                    errors={errors}
                    required="thisFieldIsRequired"
                    size="lg"
                    type="number"
                    defaultValue={0}
                    min={{
                      value: 1,
                      message: `${t('atleastOneTruck')}`,
                    }}
                  />

                  <Input
                    name="transportation_order_attributes.num_of_labors"
                    label={t('numberOfLaborers')}
                    register={register}
                    errors={errors}
                    size="lg"
                    type="number"
                    defaultValue={0}
                  />
                </SimpleGrid>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem colSpan={{ base: 7, md: 2 }}>
            <Card size="lg" height="full">
              <CardHeader>
                <SectionTitle title={t('mapView')} />
              </CardHeader>
              <CardBody>
                <MapWithDirections
                  containerStyle={{
                    width: '100%',
                    height: '100%',
                    minHeight: '300px',
                    borderRadius: '16px',
                  }}
                  origin={selectedOrigin}
                  destination={selectedDestination}
                />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>

        <CreateButtonsContainer>
          <Box
            as="fieldset"
            disabled={
              isCreateTransportationOrderLoading ? 'disabled' : undefined
            }
            flex={1}
          >
            <Grid templateColumns="repeat(6, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 3, md: 2 }}>
                <Button
                  colorScheme="gray"
                  variant="outline"
                  bgColor="white"
                  size="lg"
                  minWidth="124px"
                  width="full"
                  color="gray.600"
                  onClick={async () => {
                    const isValid = await trigger();

                    if (!isValid) {
                      return;
                    }

                    onSubmit(getValues(), true);
                  }}
                >
                  {t('saveDraft')}
                </Button>
              </GridItem>

              <GridItem colSpan={{ base: 3, md: 2 }}>
                <Button
                  colorScheme="red"
                  variant="outline"
                  bgColor="white"
                  size="lg"
                  minWidth="124px"
                  as={Link}
                  width="full"
                  to="/transportations"
                  isDisabled={isCreateTransportationOrderLoading}
                >
                  {t('cancel')}
                </Button>
              </GridItem>

              <GridItem colSpan={{ base: 6, md: 2 }}>
                <Button
                  colorScheme="primary"
                  size="lg"
                  minWidth="124px"
                  type="submit"
                  width="full"
                  flex={1}
                  textTransform="capitalize"
                >
                  {t('next')}
                </Button>
              </GridItem>
            </Grid>
          </Box>
        </CreateButtonsContainer>
      </form>

      <TransportationPackingList
        skus={skus}
        onSKUSubmit={(selectedSKU) => {
          setSKUs((prevValue) => [...prevValue, selectedSKU]);
        }}
        onSKUDelete={(id) => {
          setSKUs((prevValue) => prevValue.filter((sku) => sku.id !== id));
        }}
        onSKUChange={(id, { key, value }) => {
          setSKUs((prevValue) =>
            prevValue.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  ...(key === 'packaging'
                    ? {
                        packaging: {
                          label: getPackagingLabel(value),
                          value,
                        },
                      }
                    : { [key]: value }),
                };
              }
              return item;
            })
          );
        }}
      />

      <AddressFormDrawer
        isOpen={isAddressOpen}
        onClose={handleAddressClose}
        isLoading={isCreateAddressLoading}
        onSubmit={(data) => createAddressMutation(data)}
        backendErrors={createAddressError}
      />
    </Stack>
  );
};

export default TransportationCreate;
