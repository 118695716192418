import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import Input from 'components/shared/Inputs/Input';
import Map from 'components/shared/Inputs/Map';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BackToListButton from '../BackToListButton';
import POCsInput, { defaultPocAttributes } from '../POCsInput';

const initDefaultValues = {
  pocs_attributes: [{ ...defaultPocAttributes }],
  address: undefined,
};

const AddressFormPage = ({
  onSubmit,
  isLoading,
  defaultValues = initDefaultValues,
  isUpdate,
  backendErrors,
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    reset,
    watch,
    getValues,
    trigger,
  } = useForm({
    defaultValues,
  });

  const watchAddress = watch('address');

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <fieldset disabled={isLoading ? 'disabled' : undefined}>
        <Stack spacing={4}>
          <Flex justifyContent="space-between">
            <BackToListButton title={t('addressesList')} url="/addresses" />

            <Flex
              gap={3}
              position={{ base: 'fixed', md: 'static' }}
              width={{ base: 'full', md: 'auto' }}
              background={{ base: 'white', md: 'transparent' }}
              paddingX={{ base: 4, md: 0 }}
              paddingY={{ base: 5, md: 0 }}
              justifyContent={{ base: 'end', md: 'start' }}
              zIndex={{ base: 1400, md: 1 }}
              bottom={0}
              left={0}
            >
              {/* <Button
                colorScheme="red"
                variant="outline"
                bgColor="white"
                size="lg"
                minWidth="124px"
                onClick={() => {
                  reset(defaultValues);
                }}
              >
                {t('cancel')}
              </Button> */}
              <Button
                colorScheme="primary"
                size="lg"
                minWidth="124px"
                type="submit"
                textTransform="capitalize"
                isLoading={isLoading}
              >
                {t('confirm')}
              </Button>
            </Flex>
          </Flex>

          <SimpleGrid columns={{ base: 1, md: 2 }} gap={6}>
            <Card size="lg">
              <CardHeader>
                <SectionTitle title={t('addressesPage.addressDetails')} />
              </CardHeader>

              <CardBody>
                <Box mb={4}>
                  <Input
                    name="addressName"
                    label={t('addressName')}
                    register={register}
                    errors={errors}
                    placeholder={t('addressName')}
                    size="lg"
                    required="thisFieldIsRequired"
                  />
                </Box>

                <Controller
                  control={control}
                  name="address"
                  rules={{
                    validate: (value, formValues) => {
                      if (isUpdate) {
                        return true;
                      }

                      return (
                        !!(value?.city_name && value?.country_name) ||
                        'thisFieldIsRequired'
                      );
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Map
                      label={t('address')}
                      onAddressChange={(address) => {
                        onChange({
                          city_name: address.city?.long_name,
                          country_name: address.country?.long_name,
                          location: address.location,
                          name: address.name,
                          url: address.url,
                        });
                      }}
                      defaultValue={defaultValues.address}
                      error={t(errors.address?.message)}
                      inputSize="lg"
                      watchAddress={watchAddress}
                      value={value}
                      disabled={isUpdate}
                      hideSearch={isUpdate}
                      required
                    />
                  )}
                  defaultValue={defaultValues.address}
                />
              </CardBody>
            </Card>

            <Card size="lg">
              <CardHeader>
                <SectionTitle title={t('addressesPage.pocDetails')} />
              </CardHeader>
              <CardBody>
                <FormControl isInvalid mb={2}>
                  <FormErrorMessage>
                    {backendErrors?.pocs?.[0]}
                  </FormErrorMessage>
                </FormControl>
                <POCsInput
                  register={register}
                  errors={errors}
                  control={control}
                  getValues={getValues}
                  trigger={trigger}
                  defaultValues={defaultValues}
                />
              </CardBody>
            </Card>
          </SimpleGrid>
        </Stack>
      </fieldset>
    </form>
  );
};

export default AddressFormPage;
