import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Hide,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Show,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  addSkuToTransportation,
  cancelTransportationOrder,
  confirmTransportationOrder,
  getTransportationOrder,
  removeTransportationSKU,
  updateTransportationSKU,
} from 'api/Dashboard/transportation';
import BackToListButton from 'components/Dashboard/BackToListButton';
import OrderStatusDetails from 'components/Dashboard/OrderStatusDetails';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { getLocalStorage } from 'utils/localStorage';
import SectionDataItem from 'components/Dashboard/SectionDataItem';
import { formatDate } from 'utils/date';
import { translatePickupTime } from 'utils/orders';
import { ReactComponent as HashtagUpIcon } from 'assets/icons/hashtag-up.svg';
import { ReactComponent as CalendarAddIcon } from 'assets/icons/calendar-add.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as UserPlusBottomIcon } from 'assets/icons/user-plus-bottom.svg';
import { ReactComponent as MaximizeIcon } from 'assets/icons/maximize-3.svg';
import { ReactComponent as TruckTypeIcon } from 'assets/icons/icon.svg';
import { ReactComponent as TruckIcon } from 'assets/icons/truck.svg';
import { ReactComponent as UsersGroupIcon } from 'assets/icons/users-group.svg';
import { ReactComponent as OriginIcon } from 'assets/icons/record-circle.svg';
import { ReactComponent as DestinationIcon } from 'assets/icons/location.svg';
import { ReactComponent as LocationOutlineIcon } from 'assets/icons/location-outline.svg';
import { ReactComponent as OrganizationIcon } from 'assets/icons/buildings-alt.svg';
import { ReactComponent as CityIcon } from 'assets/icons/map-pin.svg';
import { ReactComponent as UserSquareIcon } from 'assets/icons/user-square.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobile.svg';
import MapWithDirections from 'components/shared/MapWithDirections';
import { TransportationPackingList } from './TransportationCreate';
import { packagingEnum } from 'constants/dashboard';
import Comments from 'components/Dashboard/Comments';
import { addComment } from 'api/Dashboard/comments';
import AttachmentsSection from 'components/Dashboard/Orders/AttachmentsSection';
import StatusHistorySection from 'components/Dashboard/Orders/StatusHistorySection';
import { handleNotFoundPage } from 'utils/notFoundPage';

const transportationStatuses = [
  { value: 'assigned', label: 'orderStatus.assigned' },
  { value: 'on_transit', label: 'orderStatus.on_transit' },
  { value: 'complete', label: 'orderStatus.complete' },
];

const oneItemStatuses = [
  {
    value: 'draft',
    label: 'draft',
    color: 'gray.500',
  },
  {
    value: 'cancelled',
    label: 'orderStatus.cancelled',
    color: 'red.500',
  },
];

const StatusSection = ({ activeStatus }) => {
  const { t } = useTranslation();

  const activeOneItemStatus = oneItemStatuses.find(
    (item) => item.value === activeStatus
  );

  const statuses = !!activeOneItemStatus
    ? [activeOneItemStatus]
    : transportationStatuses;

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('transportationStatus')} />
      </CardHeader>
      <CardBody>
        <OrderStatusDetails
          statuses={statuses}
          activeStatus={activeStatus}
          statusColor={activeOneItemStatus?.color}
        />
      </CardBody>
    </Card>
  );
};

const DetailsSection = ({ transportationDetails }) => {
  const { t } = useTranslation();

  const transportationOrder = transportationDetails.transportation_order ?? {};

  const truckInfo = transportationOrder.trucks?.[0];

  const transportationDetailsItems = [
    {
      icon: <HashtagUpIcon />,
      label: (
        <Text textTransform="uppercase" as="span">
          {t('id')}
        </Text>
      ),
      value: `#${transportationDetails.identifier}`,
    },
    {
      icon: <CalendarAddIcon />,
      label: t('scheduledOn'),
      value: formatDate(transportationDetails.date_of_trip, 'EEEE, dd MMM yyy'),
    },
    {
      icon: <ClockIcon />,
      label: t('pickupTime'),
      value: translatePickupTime(transportationDetails.time_of_pickup),
    },
    {
      icon: <UserPlusBottomIcon />,
      label: t('merchant'),
      value: transportationDetails.creator?.name,
    },
    {
      icon: <OrganizationIcon />,
      label: t('organizationName'),
      value: transportationDetails.organization?.name,
    },
    {
      icon: <MaximizeIcon />,
      label: t('truckSize'),
      value: t(truckInfo?.truck_size),
    },
    {
      icon: <TruckTypeIcon />,
      label: t('truckType'),
      value: t(truckInfo?.truck_type),
    },
    {
      icon: <TruckIcon />,
      label: t('numberOfTrucks'),
      value: transportationOrder.num_of_trucks,
    },
    {
      icon: <UsersGroupIcon />,
      label: t('numberOfLaborers'),
      value: transportationOrder.laborers,
    },
  ];

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('transportationDetails')} />
      </CardHeader>
      <CardBody>
        <Stack spacing={4}>
          {transportationDetailsItems.map(({ icon, label, value }, index) => (
            <SectionDataItem
              key={index}
              icon={icon}
              label={label}
              value={value}
            />
          ))}
        </Stack>
      </CardBody>
    </Card>
  );
};

const LocationSection = ({ transportationDetails }) => {
  const { t } = useTranslation();

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('locationDetails')} />
      </CardHeader>
      <CardBody>
        <MapWithDirections
          containerStyle={{
            width: '100%',
            height: '100%',
            minHeight: '350px',
            borderRadius: '16px',
          }}
          origin={{
            lat: transportationDetails.origin?.latitude,
            lng: transportationDetails.origin?.longitude,
          }}
          destination={{
            lat: transportationDetails.destination?.latitude,
            lng: transportationDetails.destination?.longitude,
          }}
        />
      </CardBody>
    </Card>
  );
};

const LocationDetailsSection = ({
  label,
  icon,
  locationDetails = {},
  isDestination,
}) => {
  const { t } = useTranslation();

  const locationDetailsItems = [
    {
      icon: <LocationOutlineIcon />,
      label: t(isDestination ? 'destinationAddress' : 'originAddress'),
      value: (
        <Text noOfLines={1} title={locationDetails.name}>
          {locationDetails.name}
        </Text>
      ),
    },
    {
      icon: <CityIcon />,
      label: t('city'),
      value: locationDetails?.city?.name,
    },
    {
      icon: <UserSquareIcon />,
      label: t(isDestination ? 'destinationPOCName' : 'originPOCName'),
      value: locationDetails.main_poc?.name,
    },
    {
      icon: <MobileIcon />,
      label: t(isDestination ? 'destinationPOCNumber' : 'originPOCNumber'),
      value: locationDetails.main_poc?.phone_number,
    },
  ];
  return (
    <Flex flexDirection="column">
      <Flex gap={2} alignItems="center" mb={4}>
        {icon}

        <Text fontWeight={600} textTransform="capitalize" fontSize="lg">
          {label}:
        </Text>
      </Flex>

      <Stack
        spacing={3}
        paddingX={6}
        paddingY={5}
        border="2px"
        borderColor={isDestination ? 'primary.200' : 'purple.200'}
        borderRadius="xl"
      >
        {locationDetailsItems.map(({ icon, label, value }, index) => (
          <SectionDataItem
            key={index}
            icon={icon}
            label={label}
            value={value}
          />
        ))}
      </Stack>
    </Flex>
  );
};

const OriginDestinationDetails = ({ transportationDetails }) => {
  const { t } = useTranslation();

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('originAndDestinationDetails')} />
      </CardHeader>
      <CardBody>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <LocationDetailsSection
            locationDetails={transportationDetails.origin}
            label={t('from')}
            icon={<OriginIcon />}
          />
          <LocationDetailsSection
            locationDetails={transportationDetails.destination}
            label={t('to')}
            icon={<DestinationIcon width="32px" height="32px" />}
            isDestination
          />
        </SimpleGrid>
      </CardBody>
    </Card>
  );
};

const TransportationPackingListSection = ({
  transportationId,
  skus,
  onSuccess,
}) => {
  const { mutate: addSkuToTransportationMutation } = useMutation(
    (payload) => addSkuToTransportation(transportationId, payload),
    {
      onSuccess: (data) => {
        onSuccess();
      },
    }
  );

  const { mutate: updateTransportationSKUMutation, isLoading } = useMutation(
    ({ payload, transportationSKUId }) =>
      updateTransportationSKU(transportationId, transportationSKUId, payload),
    {
      onSuccess: (data) => {
        onSuccess();
      },
    }
  );

  const {
    mutate: removeTransportationSKUMutation,
    isLoading: isRemoveLoading,
  } = useMutation(
    (transportationSKUId) =>
      removeTransportationSKU(transportationId, transportationSKUId),
    {
      onSuccess: (data) => {
        onSuccess();
      },
    }
  );

  return (
    <fieldset disabled={isLoading || isRemoveLoading ? 'disabled' : undefined}>
      <TransportationPackingList
        skus={skus}
        onSKUSubmit={(sku) => {
          const payload = {
            sku_id: sku.id,
            packaging: sku.packaging.value,
            quantity: +sku.quantity,
          };

          addSkuToTransportationMutation(payload);
        }}
        onSKUDelete={(id) => {
          const skuToDelete = skus.find((sku) => sku.id === id);

          removeTransportationSKUMutation(skuToDelete.id);
        }}
        onSKUChange={(id, { key, value }) => {
          const skuToEdit = skus.find((sku) => sku.id === id);

          const payload = {
            sku_id: skuToEdit.sku_id,
            ...{
              [key]: value,
              ...(key === 'packaging'
                ? { quantity: skuToEdit.quantity }
                : { packaging: skuToEdit.packaging.value }),
            },
          };

          updateTransportationSKUMutation({
            payload,
            transportationSKUId: skuToEdit.id,
          });
        }}
      />
    </fieldset>
  );
};

const CommentsSection = ({ comments = [], id, onSuccess }) => {
  const { t } = useTranslation();

  const { mutate: addCommentMutation, isLoading: isAddingComment } =
    useMutation(addComment, {
      onSuccess: (data) => {
        onSuccess();
      },
    });

  const handleCommentSubmit = ({ comment }) => {
    addCommentMutation({
      body: comment,
      commentable_id: id,
      commentable_type: 'ABTrip',
    });
  };

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={`${t('comments')} (${comments.length})`} />
      </CardHeader>
      <CardBody>
        <Comments
          comments={comments}
          onSubmit={handleCommentSubmit}
          isLoading={isAddingComment}
        />
      </CardBody>
    </Card>
  );
};

const TransportationDetails = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const { id } = useParams();
  const user = getLocalStorage('user') ?? {};
  const toast = useToast();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data = {},
    isLoading,
    refetch,
  } = useQuery(
    ['transportation-order', id],
    () => getTransportationOrder(user.organization.id, id),
    {
      onError: handleNotFoundPage,
    }
  );

  const {
    mutate: confirmTransportationOrderMutation,
    isLoading: isConfirmTransportationOrderLoading,
  } = useMutation(() => confirmTransportationOrder(user.organization.id, id), {
    onSuccess: (data) => {
      toast({
        title: t('transportationOrderConfirmedSuccessfully'),
        status: 'success',
        duration: 2000,
      });
      refetch();
    },
  });

  const {
    mutate: cancelTransportationOrderMutation,
    isLoading: isCancelTransportationOrderLoading,
  } = useMutation(() => cancelTransportationOrder(user.organization.id, id), {
    onSuccess: (data) => {
      onClose();
      toast({
        title: t('transportationOrderCanceledSuccessfully'),
        status: 'success',
        duration: 2000,
      });
      navigate('/transportations');
    },
  });

  const transportationOrder = data.transportation_order ?? {};
  const skus = data.ab_trip_skus?.map((sku) => {
    const packaging =
      sku.packaging === 'pallet_wrap' ? 'pallet' : sku.packaging;

    return {
      ...sku.sku,
      ...sku,
      sku_id: sku.sku.id,
      packaging: {
        label: packaging,
        value: packagingEnum[packaging],
      },
    };
  });

  const isDraft = transportationOrder.status === 'draft';

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  return (
    <>
      <Stack spacing={6}>
        <Box>
          <Show below="md">
            <Box mb={8} mt={4}>
              <PageTitle title={pageTitle} fontWeight={600} />
            </Box>
          </Show>
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Hide below="md">
              <BackToListButton
                title={t('transportationsList')}
                url="/transportations"
              />
            </Hide>

            {isDraft && (
              <Flex gap={4} flex={1} justifyContent="flex-end" flexWrap="wrap">
                <Button
                  size="lg"
                  colorScheme="gray"
                  bgColor="white"
                  color="red.500"
                  textTransform="capitalize"
                  width={{ base: 'full', md: 'auto' }}
                  onClick={onOpen}
                  isDisabled={isConfirmTransportationOrderLoading}
                  leftIcon={
                    <CloseIcon
                      border="2px solid"
                      borderColor="red.500"
                      borderRadius="full"
                      padding={1}
                      boxSize="24px"
                    />
                  }
                >
                  {t('cancelTrip')}
                </Button>

                {/* {!data.confirmed_at && (
                <Button
                  colorScheme="primary"
                  size="lg"
                  textTransform="capitalize"
                  minWidth="124px"
                  width={{ base: 'full', md: 'auto' }}
                  onClick={confirmTransportationOrderMutation}
                  isLoading={isConfirmTransportationOrderLoading}
                  isDisabled={isCancelTransportationOrderLoading}
                >
                  {t('confirmTrip')}
                </Button>
              )} */}
              </Flex>
            )}
          </Flex>
        </Box>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <Flex gap={6} flexDirection="column">
            <StatusSection activeStatus={transportationOrder.status} />

            <DetailsSection transportationDetails={data} />
          </Flex>

          <LocationSection transportationDetails={data} />
        </SimpleGrid>

        <OriginDestinationDetails transportationDetails={data} />

        <TransportationPackingListSection
          transportationId={id}
          skus={skus}
          onSuccess={() => refetch()}
        />

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <CommentsSection
            id={id}
            comments={data.comments}
            onSuccess={refetch}
          />

          {!isLoading && (
            <AttachmentsSection
              id={id}
              attachments={data.attachments}
              onSuccess={refetch}
              attachmentType="ABTrip"
              attachmentAlias="files"
            />
          )}
        </SimpleGrid>

        <StatusHistorySection statusHistories={data.status_histories ?? []} />
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('cancelTrip')}</ModalHeader>
          <ModalCloseButton isDisabled={isCancelTransportationOrderLoading} />
          <ModalBody>{t('areYouSureYouWantToCancelTheTrip')}</ModalBody>

          <ModalFooter gap={2}>
            <Button
              onClick={onClose}
              variant="ghost"
              size="lg"
              isDisabled={isCancelTransportationOrderLoading}
            >
              {t('close')}
            </Button>
            <Button
              colorScheme="red"
              size="lg"
              isLoading={isCancelTransportationOrderLoading}
              isDisabled={isCancelTransportationOrderLoading}
              onClick={() => cancelTransportationOrderMutation()}
            >
              {t('cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TransportationDetails;
