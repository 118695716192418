import debounce from 'lodash.debounce';
import { useRef, useState } from 'react';
import { Select } from 'chakra-react-select';
import { useQuery } from 'react-query';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spinner,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { inputSizes } from 'constants/theme';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import OptionalIndicator from 'components/shared/OptionalIndicator';

const AsyncSelect = ({
  performSearchRequest,
  entityKey,
  error,
  required,
  label,
  onChange,
  defaultValue,
  value,
  isMulti,
  size = 'md',
  placeholder,
  searchKey = 'name_cont',
}) => {
  const { t } = useTranslation();

  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');

  const {
    refetch,
    isLoading,
    isRefetching,
    error: fetchError,
    data = {},
  } = useQuery(
    // searchText ? [entityKey, searchText] : [entityKey],
    [entityKey],
    async () => {
      const urlSearchParams = new URLSearchParams({
        [`q[${searchKey}]`]: searchText,
      }).toString();

      return await performSearchRequest(urlSearchParams);
    },
    {
      refetchOnMount: true,
    }
  );

  useDidUpdateEffect(() => {
    refetch();
  }, [searchText]);

  const options =
    data.data?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) ?? [];

  const handleSearchDebounced = useRef(
    debounce((searchText) => setSearchText(searchText), 300)
  ).current;

  const handleInputChange = (inputText, meta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      setInputText(inputText);
      handleSearchDebounced(inputText);
    }
  };

  if (isLoading) {
    return <Spinner color="primary.500" />;
  }

  // TODO: check better approach
  const currentValue = options?.find((option) => option.value === value);

  return (
    <FormControl isInvalid={error} isRequired={!!required}>
      {label && (
        <FormLabel
          textTransform="capitalize"
          color="gray.700"
          requiredIndicator={false}
          optionalIndicator={<OptionalIndicator />}
        >
          {label}
        </FormLabel>
      )}
      {fetchError && 'An error has occurred'}

      <Select
        options={options}
        isClearable={true}
        inputValue={inputText}
        onInputChange={handleInputChange}
        onChange={onChange}
        isLoading={!!searchText && isRefetching}
        filterOption={null}
        styles={{
          menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 1401,
          }),
        }}
        chakraStyles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: 'gray.50',
            textTransform: 'capitalize',
            borderRadius: 'xl',
            fontSize: ['lg', 'md'].includes(size) ? 'md' : 'sm',
            minHeight: inputSizes[size],
            height: isMulti ? 'auto' : inputSizes[size],
            borderWidth: '1px',
            borderColor: 'gray.200',
            boxShadow: 'none',
            _invalid: {
              boxShadow: 'none',
              borderColor: 'red.500',
            },
          }),
          input: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: 'none !important',
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: 'sm',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            marginTop: 1,
            zIndex: 1401,
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            display: 'flex',
            flexDirection: 'column',
            paddingInline: 2,
            gap: 2,
            borderRadius: 'xl',
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 'md',
            textTransform: 'capitalize',
            fontSize: 'sm',
            _selected: {
              backgroundColor: 'primary.500',
              color: 'white',
            },
          }),
          multiValue: (baseStyles) => ({
            ...baseStyles,
            background: 'primary.500',
          }),
          multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
          }),
          multiValueRemove: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
          }),
        }}
        isMulti={isMulti}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={currentValue}
      />
      <FormErrorMessage>{t(error?.message)}</FormErrorMessage>
    </FormControl>
  );
};

export default AsyncSelect;
